<template>
  <div id="item-detail-page">

    <vs-alert color="danger" title="Error Fetching Product Data" :active="!item">
      <span>
        <span>Something went wrong while fetching product data. Hey! don't worry, we have more items. Check </span><router-link :to="{name:'products'}" class="text-inherit underline">All Items</router-link>
      </span>
    </vs-alert>
    <vx-card v-if="item" :key="item.id">
      <template slot="no-body">

        <div class="item-content">

          <!-- Item Main Info -->
          <div class="product-details md:p-6">
            <div class="vx-row mt-6">
              <div class="vx-col md:w-2/3">

                <div class="vx-row">
                  <div class="vx-col md:w-1/2 w-full flex justify-center">
                  <div class="product-img-container ">
                    <!--<viewer v-if="item.files.length">-->
                      <!--<img :src="base_image.path" :alt="item.name" class="responsive cursor-pointer mx-auto mb-10 w-3/5">-->
                      <!--<div class="vx-row">-->
                        <!--<img style="max-width: 80px; height: 80px; border:#e3dddd solid 1px" class="cursor-pointer p-1" v-for="photo in item.files" :src="photo.path" :key="photo.id">-->
                      <!--</div>-->
                    <!--</viewer>-->
                    <viewer :images="images">
                      <img :src="base_image.path" alt="product image" style="cursor: zoom-in" class="responsive hidden md:block mx-auto w-full">
                      <carousel :perPageCustom="[[768, 4]]" :paginationPadding="2" :perPage="1" paginationColor="rgb(255, 71, 71)" paginationActiveColor="#41b883">
                        <slide v-for="photo in item.files" :key="photo.id">
                          <img class="responsive" style="border:#e3dddd solid 1px; cursor: zoom-in" :src="photo.path">
                        </slide>
                      </carousel>
                    </viewer>
                  </div>
                </div>
                <!-- Item Content -->
                <div class="vx-col mt-2 md:w-1/2 w-full">

                  <h3>{{ item.name }}</h3>

                  <p class="my-2 flex">
                    <router-link :to="'/products?category='+c.slug" v-for=" (c) in item.categories" :key="c.id"><vs-chip>{{c.name}}</vs-chip></router-link>
                  </p>
                  <div  v-if="special_price" >
                    <p class="text-2xl leading-none font-medium text-primary mr-4 mt-2">{{ special_price.price | currency }}</p>
                    <p class="flex items-center flex-wrap mb-2">
                      <span style="text-decoration: line-through" class="text-xl leading-none  mr-4 mt-2">{{ item.price.amount | currency }}</span>&nbsp;
                      <vs-chip class="mt-2">{{special_price.percentage}}%</vs-chip>
                      <span class="pl-4 mr-2 mt-2 border border-solid d-theme-border-grey-light border-t-0 border-b-0 border-r-0"><star-rating :show-rating="false" :rating="rating.star" :increment=".1" :star-size="15" read-only /></span>
                      <span class="cursor-pointer leading-none mt-2">{{rating.total>0? rating.total+' reviews': 'No review yet'}}</span>
                    </p>
                  </div>

                  <p v-else class="flex items-center flex-wrap mb-2">
                    <span class="text-2xl leading-none text-primary mr-4 mt-2">{{ item.price.amount | currency }}</span>
                    <span class="pl-4 mr-2 mt-2 border border-solid d-theme-border-grey-light border-t-0 border-b-0 border-r-0"><star-rating :show-rating="false" :rating="rating.star" :increment=".1"  :star-size="15" read-only /></span>
                    <span class="cursor-pointer leading-none mt-2">{{rating.total>0? rating.total+' reviews': 'No review yet'}}</span>
                  </p>
                  <div v-if="$store.state.windowWidth < 767">
                    <div v-if="special_price">
                      <h3 class="text-warning mb-3">This special price ends in :</h3>
                      <vue-countdown :time="special_price.remain">
                        <template slot-scope="props">
                          <table width="100%" class="md:text-2xl text-center">
                            <tr>
                              <td><span class="rounded-lg text-primary justify-around p-2 flex" :style="{background: `rgba(var(--vs-warning),.15)`}" >{{ props.days }} <br>Days</span></td>
                              <td><span class="rounded-lg text-primary justify-around p-2 flex" :style="{background: `rgba(var(--vs-warning),.15)`}" >{{ props.hours }} <br>Hour</span></td>
                              <td><span class="rounded-lg text-primary justify-around p-2 flex" :style="{background: `rgba(var(--vs-warning),.15)`}" >{{ props.minutes }} <br>Min</span></td>
                              <td><span class="rounded-lg text-primary justify-around p-2 flex" :style="{background: `rgba(var(--vs-warning),.15)`}" >{{ props.seconds }} <br>Sec</span></td>
                            </tr>
                          </table>
                        </template>
                      </vue-countdown>
                      <p class="mt-2">This price of this item will revert back to {{item.price.amount | currency}} at the end of this countdown</p>
                    </div>
                    <vs-divider />
                    <div id="options_selection">
                      <div v-for="option in item.options" :key="option.id">
                        <div v-if="option.type == 'radio'">
                          <h4>{{option.name}} <span v-if="option.is_required" class="text-primary">*</span></h4>
                          <div class="flex my_radio">
                              <vs-radio v-for="value in option.values" :key="value.id" @change="radio_changed" @input="additionalCost[option.id] = getAdditionalCost(value)" v-validate="{required:option.is_required}" :name="option.name" v-model="options[option.id]" :vs-value="value.id">{{value.label}} {{getAdditionalCost(value)>0? ' +'+$options.filters.currency(getAdditionalCost(value)):''}}</vs-radio>
                          </div>
                          <span class="text-danger text-sm" v-show="errors.has(option.name)">{{ errors.first(option.name) }}</span>
                        </div>
                        <div v-if="option.type == 'dropdown'">
                          <h4>{{option.name}} <span v-if="option.is_required" class="text-primary">*</span></h4>
                          <v-select :reduce="op=>{ additionalCost[option.id] = op.cost; return op.value}" :options="getDropdown(option.values)" v-validate="{required:option.is_required}" :name="option.name"  :clearable="false" v-model="options[option.id]" />
                          <span class="text-danger text-sm" v-show="errors.has(option.name)">{{ errors.first(option.name) }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <vs-divider />
                  <div v-if="$store.state.settings.features" class="vs-list product-sales-meta-list px-0 pt-4">
                    <div v-for="(info, index) in $store.state.settings.features" :key="index"  class="vs-list--item p-0 border-none"><!---->
                      <div class="vs-list--icon"><i class="vs-icon notranslate icon-scale feather null text-primary" :class="info.icon"></i>
                      </div>
                      <div class="list-titles">
                        <div class="vs-list--title" v-html="info.title"></div>
                      </div>
                    </div>
                  </div>
                  <vs-divider />
                  <div class="vx-col hidden md:block w-full">
                    <div class="vx-row flex items-start mb-4">

                      <!-- Add To Cart Button -->
                      <div class="vx-col w-full md:w-1/2">
                      <quick-order :product_name="item.name"  :id="item.id"></quick-order>
                      </div>
                      <div class="vx-col w-full md:w-1/2">
                      <vs-button style="width: 100%"
                        key="border"
                        class="mb-4 pl-0 pr-0 wobble-hor-bottom"
                        type="border"
                        icon-pack="feather"
                        icon="icon-phone-call"
                        color="danger">
                        <a :href="'tel:'+himu_phone" style="color: unset">Help {{himu_phone}}</a>
                      </vs-button>
                      </div>
                      <!-- /Wishlist Button -->

                    </div>
                  </div>
                  <div v-html="item.short_description"></div>
                  <vs-divider />
                  <div class="vx-col mt-3 w-full mb-base">
                    <vx-card>
                      <template slot="no-body">
                        <video-player v-if="item.youtube_url" ref="player" class="media-video-player w-full" :options="{techOrder: ['youtube'], sources: [{ type: 'video/youtube', src: this.item.youtube_url}],youtube: { ytControls: 0 },crossOrigin:'true',fluid:true}" />
                      </template>
                    </vx-card>
                  </div>
                </div>
                  <div class="vx-col w-full text-center">
                    <div class="header-title">
                      <h3>Reviews</h3>
                    </div>
                    <vx-card>
                      <div class="vx-row">
                        <div class="vx-col w-full md:w-2/3 text-left">
                          <div class="text-center" v-if="reviews_filtered.length == 0">
                            <vs-alert color="warning" active="true">
                                No review posted yet.
                            </vs-alert>
                          </div>
                          <div v-for="(rv, i) in reviews_filtered" :key="i" class="p-2 border border-solid border-r-0 border-l-0 border-t-0  d-theme-border-grey-light">
                            <div class="justify-between flex">
                              <p class="flex">Valued Customer</p>
                              <div>
                                <star-rating :show-rating="false" :rating="rv.rating" :increment=".1" :star-size="15" read-only />
                              </div>

                            </div>
                            <small>{{elapsedTime(rv.created_at)}},  <span class="text-success"> <feather-icon svg-classes="h-3 w-3" icon="CheckCircleIcon"></feather-icon> Purchase Verified</span></small>
                            <p class="text-xl mt-3">{{rv.comment}}</p>
                          </div>
                          <div class="text-center">
                            <span  v-if="item.reviews.length>3" class="show-all bg-primary text-white shadow-drop" @click="show_all_cat=!show_all_cat">{{show_all_cat? 'Show less': 'Show all'}}</span>
                          </div>
                        </div>
                        <div class="vx-col w-full hidden md:block md:w-1/3">
                          <feather-icon icon="StarIcon" class="p-3 inline-flex rounded-full" :class="[`text-primary`]" :style="{background: `rgba(var(--vs-warning),.15)`}"></feather-icon>
                          <p>{{rating.star}} out of 5, {{rating.total}} reviews</p>
                          <ul class="mt-10">
                            <li class="mb-2">
                              <div  class="flex justify-between items-center">
                                <div class="flex items-center flex-wrap">
                                  <feather-icon icon="StarIcon" :svgClasses="['text-warning cursor-pointer stroke-current h-6 w-6']" />
                                  <feather-icon icon="StarIcon" :svgClasses="['text-warning stroke-current h-6 w-6']" />
                                  <feather-icon icon="StarIcon" :svgClasses="['text-warning stroke-current h-6 w-6']" />
                                  <feather-icon icon="StarIcon" :svgClasses="['text-warning stroke-current h-6 w-6']" />
                                  <feather-icon icon="StarIcon" :svgClasses="['text-warning stroke-current h-6 w-6']" />
                                </div>
                                <span>({{rating.five}})</span>
                              </div>
                            </li>
                            <li class="mb-2">
                              <div  class="flex justify-between items-center">
                                <div class="flex items-center flex-wrap">
                                  <feather-icon icon="StarIcon" :svgClasses="['text-warning cursor-pointer stroke-current h-6 w-6']" />
                                  <feather-icon icon="StarIcon" :svgClasses="['text-warning stroke-current h-6 w-6']" />
                                  <feather-icon icon="StarIcon" :svgClasses="['text-warning stroke-current h-6 w-6']" />
                                  <feather-icon icon="StarIcon" :svgClasses="['text-warning stroke-current h-6 w-6']" />
                                  <feather-icon icon="StarIcon" :svgClasses="['cursor-pointer stroke-current h-6 w-6']" />
                                </div>
                                <span>({{rating.four}})</span>
                              </div>
                            </li>
                            <li class="mb-2">
                              <div  class="flex justify-between items-center">
                                <div class="flex items-center flex-wrap">
                                  <feather-icon icon="StarIcon" :svgClasses="['text-warning cursor-pointer stroke-current h-6 w-6']" />
                                  <feather-icon icon="StarIcon" :svgClasses="['text-warning stroke-current h-6 w-6']" />
                                  <feather-icon icon="StarIcon" :svgClasses="['text-warning stroke-current h-6 w-6']" />
                                  <feather-icon icon="StarIcon" :svgClasses="['cursor-pointer stroke-current h-6 w-6']" />
                                  <feather-icon icon="StarIcon" :svgClasses="['cursor-pointer stroke-current h-6 w-6']" />
                                </div>
                                <span>({{rating.three}})</span>
                              </div>
                            </li>
                            <li class="mb-2">
                              <div  class="flex justify-between items-center">
                                <div class="flex items-center flex-wrap">
                                  <feather-icon icon="StarIcon" :svgClasses="['text-warning cursor-pointer stroke-current h-6 w-6']" />
                                  <feather-icon icon="StarIcon" :svgClasses="['text-warning stroke-current h-6 w-6']" />
                                  <feather-icon icon="StarIcon" :svgClasses="['cursor-pointer stroke-current h-6 w-6']" />
                                  <feather-icon icon="StarIcon" :svgClasses="['cursor-pointer stroke-current h-6 w-6']" />
                                  <feather-icon icon="StarIcon" :svgClasses="['cursor-pointer stroke-current h-6 w-6']" />
                                </div>
                                <span>({{rating.two}})</span>
                              </div>
                            </li>
                            <li class="mb-2">
                              <div  class="flex justify-between items-center">
                                <div class="flex items-center flex-wrap">
                                  <feather-icon icon="StarIcon" :svgClasses="['text-warning cursor-pointer stroke-current h-6 w-6']" />
                                  <feather-icon icon="StarIcon" :svgClasses="['cursor-pointer stroke-current h-6 w-6']" />
                                  <feather-icon icon="StarIcon" :svgClasses="['cursor-pointer stroke-current h-6 w-6']" />
                                  <feather-icon icon="StarIcon" :svgClasses="['cursor-pointer stroke-current h-6 w-6']" />
                                  <feather-icon icon="StarIcon" :svgClasses="['cursor-pointer stroke-current h-6 w-6']" />
                                </div>
                                <span>({{rating.one}})</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </vx-card>

                  </div>
                  <div class="vx-col mt-2 w-full">
                    <div class="header-title">
                      <h3>Description</h3>
                    </div>
                    <vx-card>
                      <div v-html="item.description" class="tab-text">

                      </div>
                    </vx-card>
                  </div>
                  <div class="vx-col mt-2 w-full">
                    <div class="header-title">
                      <h3>Additional Info</h3>
                    </div>
                    <vx-card>
                      <div v-if="item.attributeSet" v-for="(attrs, key) in item.attributeSet" :key="key">
                        <table style="width:100%" class="border-collapse">
                          <tr v-for="(attr, i) in attrs" :key="i">
                            <td width="30%" class="p-2 border border-solid d-theme-border-grey-light">{{attr.attribute.name}}</td>
                            <td class="border border-solid d-theme-border-grey-light text-center"><p v-for="v in attr.values" :key="v.attribute_value_id">{{v.attribute_value.value}}</p></td>
                          </tr>
                        </table>
                      </div>
                    </vx-card>
                  </div>
                  <div v-if="item.related_products.length > 0" class="vx-col relative_item w-full">
                    <div class="header-title">
                      <h3>Related Items</h3>
                    </div>
                    <div class="items-grid-view vx-row match-height">
                      <div class="vx-col lg:w-1/5 sm:w-1/3 w-1/2" style="padding: 0 0.2rem;" v-for="rp in item.related_products" :key="rp.id">
                        <item-grid-view :item="rp"></item-grid-view>
                      </div>
                    </div>
                  </div>
                  <div v-if="item.cross_sell_products.length > 0" class="vx-col relative_item w-full">
                    <div class="header-title">
                      <h3>Cross sells</h3>
                    </div>
                    <div class="items-grid-view vx-row match-height">
                      <div class="vx-col lg:w-1/5 sm:w-1/3 w-1/2" style="padding: 0 0.2rem;" v-for="rp in item.cross_sell_products" :key="rp.id">
                        <item-grid-view :item="rp"></item-grid-view>
                      </div>
                    </div>
                  </div>
                  <div v-if="item.up_sell_products.length > 0" class="vx-col relative_item w-full">
                    <div class="header-title">
                      <h3>Up sells</h3>
                    </div>
                    <div class="items-grid-view vx-row match-height">
                      <div class="vx-col lg:w-1/5 sm:w-1/3 w-1/2" style="padding: 0 0.2rem;" v-for="rp in item.up_sell_products" :key="rp.id">
                        <item-grid-view :item="rp"></item-grid-view>
                      </div>
                    </div>
                  </div>
            </div>
              </div>


              <div v-if="$store.state.windowWidth > 767" class="vx-col w-full md:w-1/3 ">
                <div class="sticky-container">
                  <vx-card class="sticky-content">
                    <div class="mb-2">
                      <h5 class="mb-2">Availability: <span :class="{'text-success':item.in_stock, 'text-warning':!item.in_stock}"> {{item.in_stock?'In stock': 'Sold out'}}</span></h5>
                    </div>
                    <p v-if="special_price" class="flex items-center flex-wrap">
                      <span class="text-2xl leading-none font-medium text-primary mr-4 mt-2">{{ special_price.price | currency }}</span>
                      <span style="text-decoration: line-through" class="text-xl leading-none mr-4 mt-2">{{ item.price.amount | currency }}</span>
                      <vs-chip class="mt-2">{{special_price.percentage}}%</vs-chip> &nbsp;
                    </p>
                    <p v-else class="flex items-center flex-wrap">
                      <span class="text-2xl leading-none font-medium text-primary mr-4 mt-2">{{ item.price.amount | currency }}</span>
                    </p>
                    <div class="hidden md:block" v-if="special_price">
                      <h3 class="text-warning mb-3">This special price ends in :</h3>
                      <vue-countdown :time="special_price.remain">
                        <template slot-scope="props">
                          <table width="100%" class="md:text-2xl text-center">
                            <tr>
                              <td><span class="rounded-lg text-primary justify-around p-2 flex" :style="{background: `rgba(var(--vs-warning),.15)`}" >{{ props.days }} <br>Days</span></td>
                              <td><span class="rounded-lg text-primary justify-around p-2 flex" :style="{background: `rgba(var(--vs-warning),.15)`}" >{{ props.hours }} <br>Hour</span></td>
                              <td><span class="rounded-lg text-primary justify-around p-2 flex" :style="{background: `rgba(var(--vs-warning),.15)`}" >{{ props.minutes }} <br>Min</span></td>
                              <td><span class="rounded-lg text-primary justify-around p-2 flex" :style="{background: `rgba(var(--vs-warning),.15)`}" >{{ props.seconds }} <br>Sec</span></td>
                            </tr>
                          </table>
                        </template>
                      </vue-countdown>
                      <p class="mt-2">This price of this item will revert back to {{item.price.amount | currency}} at the end of this countdown</p>
                    </div>
                    <vs-divider />
                    <div>
                      <div v-for="option in item.options" :key="option.id">
                        <div v-if="option.type == 'radio'">
                          <h4>{{option.name}} <span v-if="option.is_required" class="text-primary">*</span></h4>

                              <div class="flex my_radio">
                                <vs-radio v-for="value in option.values" :key="value.id" @change="radio_changed" @input="additionalCost[option.id] = getAdditionalCost(value)" v-validate="{required:option.is_required}" :name="option.name" v-model="options[option.id]" :vs-value="value.id">{{value.label}} {{getAdditionalCost(value)>0? ' +'+$options.filters.currency(getAdditionalCost(value)):''}}</vs-radio>

                              </div>
                          <span class="text-danger text-sm" v-show="errors.has(option.name)">{{ errors.first(option.name) }}</span>
                        </div>
                        <div v-if="option.type == 'dropdown'">
                          <h4>{{option.name}} <span v-if="option.is_required" class="text-primary">*</span></h4>
                          <v-select :reduce="op=>{ additionalCost[option.id] = op.cost; return op.value}" v-validate="{required:option.is_required}" :name="option.name" :options="getDropdown(option.values)" :clearable="false" v-model="options[option.id]" />
                          <span class="text-danger text-sm" v-show="errors.has(option.name)">{{ errors.first(option.name) }}</span>
                        </div>
                      </div>
                    </div>
                    <vs-divider />
                    <div v-if="item.in_stock" class="mb-4 mt-4">
                      <div class="flex justify-between">
                        <small class="font-semibold">Low stock: {{item.qty}} left</small>
                      </div>
                      <vs-progress :height="12" :percent="10" class="block mt-1 shadow-md"></vs-progress>
                    </div>
                    <div class="vx-row m-2">
                      <div class="vx-col w-full">
                        <div class="flex flex-wrap items-start mb-4">

                          <!-- Add To Cart Button -->
                          <vs-button
                            class="mr-4 mb-4 pl-3 pr-3 heartbeat"
                            icon-pack="feather"
                            icon="icon-shopping-cart"
                            v-if="!isInCart(item.id)"
                            @click="toggleItemInCart(item)">
                            ADD TO CART
                          </vs-button>

                          <vs-button
                            v-else
                            color="success"
                            class="mr-4 mb-4 pl-3 pr-3"
                            icon-pack="feather"
                            icon="icon-shopping-cart" @click="$root.$emit('showCart')">
                            VIEW IN CART
                          </vs-button>
                          <!-- /Add To Cart Button -->

                          <!-- Wishlist Button -->
                          <vs-button
                            v-if="isInWishList(item.id)"
                            key="filled"
                            class="mb-4 pl-3 pr-3"
                            icon-pack="feather"
                            icon="icon-heart"
                            color="danger"
                            @click="toggleItemInWishList(item)">
                            REMOVE
                          </vs-button>

                          <vs-button
                            v-else
                            key="border"
                            class="mb-4 pl-3 pr-3"
                            type="border"
                            icon-pack="feather"
                            icon="icon-heart"
                            color="danger"
                            @click="toggleItemInWishList(item)">
                            WHISHLIST
                          </vs-button>
                          <!-- /Wishlist Button -->

                        </div>
                      </div>
                    </div>
                  </vx-card>
                </div>
              </div>
            </div>
          </div>

        </div>
      </template>
    </vx-card>
    <div style="margin: 0 -1.2rem" class="navbottom flex justify-between md:hidden">
      <router-link to="/" exact>
        <feather-icon icon="HomeIcon" class="fill-current" :class="{'w-3 h-3': false}"></feather-icon>
      </router-link>
      <div class="flex cart_button_bottom" >
        <div class="parallelogram">
          <span class="parallelogram-inner"><quick-order :text-only="true" :product_name="item.name" :id="item.id"></quick-order></span>
        </div>
        <div class=" parallelogram" :class="{'view-to-cart':isInCart(item.id),'add-to-cart':!isInCart(item.id)}">
          <span v-if="isInCart(item.id)" class="parallelogram-inner " @click="$root.$emit('showCart')">view in cart</span>
          <span v-else class="parallelogram-inner" @click="toggleItemInCart(item)">Add to cart</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import StarRating from 'vue-star-rating'
  import VueCountdown from '@chenfengyuan/vue-countdown';
  import { videoPlayer }     from 'vue-video-player';
  import 'video.js/dist/video-js.css';
  import 'viewerjs/dist/viewer.css';
  import { Carousel, Slide } from 'vue-carousel';
  import videojs from 'video.js';
  import Youtube from 'videojs-youtube';
  import QuickOrder from "./components/QuickOrder";
  import vSelect from 'vue-select'
  var VueScrollTo = require('vue-scrollto');
    export default {
        components:{
          ItemGridView: () => import("./components/ItemGridView.vue"),
          QuickOrder,
          StarRating,
          VueCountdown,
          videoPlayer,
          Carousel,
          Slide,
          'v-select': vSelect,
        },
        name: "Product",
        data(){
            return {
              slug:this.$route.params.slug,
              himu_phone:process.env.VUE_APP_PHONE,
              options:{},
              additionalCost:{},
              show_all_cat:false,
            }
        },
        watch: {
          '$route'(to, from){
            this.slug = to.params.slug;
            this.updateView();
          },
        },
        computed:{
          reviews_filtered(){
            if (this.show_all_cat)
              return this.item.reviews;
            return this.item.reviews.slice(0, 3);
          },
          images(){
            return this.item.files.map(x=>x.path)
          },
          item(){
           return this.$store.state.products.find(item=>item.slug == this.slug);
          },
          base_image(){
            return this.item.files.find(function (f) {
              return f.pivot.zone ==='base_image'
            })
          },
          rating(){
            var t = this.item.reviews.length;
            var star = 0;
            var one= 0, two=0,three=0, four=0, five=0;
            if ( t > 0){
              var ts = 0;
              this.item.reviews.forEach(function (review) {
                if (review.rating == 5)
                  five++;
                if (review.rating == 4)
                  four++;
                if (review.rating == 3)
                  three++;
                if (review.rating == 2)
                  two++;
                if (review.rating == 1)
                  one++;
                ts+=review.rating;
              })
              star = ts/t;
            }
            return {
              one,two, three, four, five,
              total:t,
              star: parseFloat(star.toFixed(1))
            }
          },
          isInCart() {
            return (itemId) => this.$store.getters['eCommerce/isInCart'](itemId)
          },
          isInWishList() {
            return (itemId) => this.$store.getters['eCommerce/isInWishList'](itemId)
          },
          price(){
            return this.special_price?this.special_price.price:this.item.price.amount;
          },
          special_price(){
            if (this.item.special_price_end){
              var end_date = Date.parse(this.item.special_price_end);
              var today = new Date();
              var percentage = ((this.item.special_price.amount-this.item.price.amount)/this.item.price.amount)*100;
              if (end_date>today){
                return {
                  price:this.item.special_price.amount,
                  percentage: percentage.toFixed(0),
                  end_date: end_date,
                  remain: end_date-today
                };
              }
              return false;
            }
            return false;
          },
        },
        methods:{
          radio_changed(){
           var x =  document.getElementsByClassName("vs-radio--input");
            var i;
            for (i = 0; i < x.length; i++) {
              if (x[i].checked){
                x[i].parentElement.classList.add("radio_checked");
              } else{
                x[i].parentElement.classList.remove("radio_checked");
              }

            }
          },
          updateView(){
            this.axios.get('/product/viewed/'+this.slug).then(function (res) {
            })
          },
          getAdditionalCost(value){
            var price = parseFloat(value.price_type == 'fixed'?value.price.amount:this.price*(parseFloat(value.price/100)));
            return price||0;

          },
          getDropdown(options){
            let data = [],
            app = this,
              c = 0;
            options.forEach(function (value) {
              c=app.getAdditionalCost(value)
              data.push({label:value.label+(c>0?" +"+app.$options.filters.currency(c):'') , value:value.id, cost:c})
            });
            return data
          },
          toggleItemInCart(item) {
            this.m_pixel_AddToCart({product: item.name});
            this.$validator.validateAll().then(result => {
              if (result) {
                var addCost = 0,
                op = this.additionalCost
                Object.keys(op).forEach(function (key) {
                  addCost+=parseFloat(op[key]);
                });
                item.cal_price = parseFloat(this.price)+addCost
                item.selected_options = this.options;
                this.$store.dispatch('eCommerce/toggleItemInCart', item)
                if (Object.keys(this.$store.state.eCommerce.coupon).length>0){
                  this.m_updateCoupon(this.$store.state.eCommerce.coupon.code);
                }
                this.$root.$emit('shakeCart');
              } else {
                this.$vs.notify({
                  title: 'Select options',
                  text: 'Please select all required option',
                  color: 'warning'
                })
                if (this.$store.state.windowWidth < 767 ){
                  var options = {
                    // container: '#options_selection',
                    easing: 'ease-in',
                    // offset: -80,
                    force: true,
                    cancelable: true,
                    x: false,
                    y: true
                  }

                  VueScrollTo.scrollTo('#options_selection', 600, options)
                }


              }
            })

          },
          toggleItemInWishList(item) {
            this.m_pixel_AddToWishlist({product: item.name});
            this.$store.dispatch('eCommerce/toggleItemInWishList', item)
          },
          elapsedTime(startTime) {
            let x        = new Date(startTime)
            let now      = new Date()
            var timeDiff = now - x
            timeDiff    /= 1000

            var seconds = Math.round(timeDiff)
            timeDiff    = Math.floor(timeDiff / 60)

            var minutes = Math.round(timeDiff % 60)
            timeDiff    = Math.floor(timeDiff / 60)

            var hours   = Math.round(timeDiff % 24)
            timeDiff    = Math.floor(timeDiff / 24)

            var days    = Math.round(timeDiff % 365)
            timeDiff    = Math.floor(timeDiff / 365)

            var years   = timeDiff

            if (years > 0) {
              return years + (years > 1 ? ' Years ' : ' Year ') + 'ago'
            } else if (days > 0) {
              return days + (days > 1 ? ' Days ' : ' Day ') + 'ago'
            } else if (hours > 0) {
              return hours + (hours > 1 ? ' Hrs ' : ' Hour ') + 'ago'
            } else if (minutes > 0) {
              return minutes + (minutes > 1 ? ' Mins ' : ' Min ') + 'ago'
            } else if (seconds > 0) {
              return seconds + (seconds > 1 ? ' sec ago' : ' just now')
            }

            return 'Just Now'
          },
        },
        mounted(){
           this.updateView();
           document.title = this.item.name;
        }
    }
</script>

<style scoped>
  .sticky-container{
    position: relative;
    height: 100%;
  }
  .sticky-content{
    position: sticky;
    top: 80px;
  }
  .my_radio >>>.vs-radio--label{
    padding: 5px;
    border: 2px solid #783392;
    border-radius: 5px;
    margin: 2px;
  }
  >>> .vs-radio{
    display: none;
  }
  .my_radio >>>.radio_checked .vs-radio--label{

    background: #783392;
    color: white;
  }
</style>
