<template>
    <div>
      <p v-if="textOnly" @click="addQuickOrder">Quick Order</p>
      <vs-button v-else style="width: 100%" :id="'quick_order_'+id"
        @click="addQuickOrder"
        class="mr-4 mb-4 wobble-hor-bottom vs-con-loading__container"
        icon-pack="feather"
        icon="icon-shopping-cart">
        Quick Order
      </vs-button>
    </div>
</template>

<script>
  import 'sweetalert2/dist/sweetalert2.min.css';
    export default {
        name: "QuickOrder",
        props:{
          textOnly:{
              type:Boolean,
              default:false,
            },
            id :{
              required: true,
            },
            product_name:{
              required: true
            }
        },
        data(){
          return {
            activePrompt:false,
            phone:'',
            activeOk:false,
          }
        },
        computed:{
          validNumber(){
            return (this.phone && this.phone.length == 11);
          }
        },
        methods:{
          saveNumber(){
            if (this.phone && this.phone.length == 11){
              this.$store.commit('UPDATE_USER', {phone: this.phone});
              this.addQuickOrder();
            }

          },
          showResponse(data){
            this.$vs.loading.close("#quick_order_"+this.id+" > .con-vs-loading");
            var app = this;
            if (data.errors){
              Object.keys(data.errors).forEach(function (er) {
                app.$vs.notify({
                  title: 'Error',
                  text:data.errors[er][0],
                  color:'danger',
                });
              })
            }else{
              this.$vs.notify({
                title: 'Quick order',
                text:data.message,
                color:data.type,
              });
              if (data.type =='success'){
                this.$swal({
                  title:'আপনার অর্ডারটি নিশ্চিত হয়েছে, ধন্যবাদ।',
                  text:'বিস্তারিত জানতে আমরা আপনাকে ফোন করবো (সকাল ৯ টা থেকে রাত্র ১১ টার মধ্যে )',
                  icon:'success',
                  showCancelButton: true,
                  showConfirmButton: false,
                  cancelButtonText:'Close',
                  reverseButtons:true,
                  timer:6000,
                  customClass:{
                    actions:'justify-end',
                  },
                });
              }
            }
          },
          addQuickOrder(){
            this.m_pixel_QuickOrder({product: this.product_name});
            if (!this.$auth.check()){
              this.$swal({
                title: 'Just give us your mobile number',
                icon: 'info',
                inputPlaceholder: 'Type your mobile number',
                input: 'text',
                showCancelButton: true,
                confirmButtonText:'Place order',
                cancelButtonText:'Cancel',
                reverseButtons:true,
                customClass:{
                  actions:'justify-end',
                },
                inputValidator: (value) => {
                  return new Promise((resolve) => {
                    var regex = RegExp(/\0?1[3456789][0-9]{8}\b/);
                    if (regex.test(value)){
                      resolve()
                    }else {
                      resolve('Enter valid number :)')
                    }
                  })
                }
              }).then(
                result => {
                  if (result.value){
                    this.$vs.loading({
                      container: "#quick_order_"+this.id,
                      scale: 0.45
                    });
                    this.axios.post('quick-orders', {product_id:this.id, phone:result.value}).then(({data})=>{
                      this.showResponse(data);
                    })
                  }
                }
              );
            }else{
              this.$vs.loading({
                container: "#quick_order_"+this.id,
                scale: 0.45
              });
              this.axios.post('quick-orders', {product_id:this.id, phone:this.$store.state.user.phone}).then(({data})=>{
                this.showResponse(data);
              })
            }

          },
        }
    }
</script>

<style scoped>

</style>
